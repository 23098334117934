<template>
  <v-card class="pa-0">
    <v-toolbar min-width="100%" dark color="primary">
      <v-card-title>Billing</v-card-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <DataTableSSR
      apiEndPoint="/billing/"
      :headers="headers"
      :instantLoad="true"
    >
      <template #[`item.bill_amount`]="{ item }">
        &#x9F3; &nbsp;{{ item.bill_amount }}&nbsp;BDT
      </template>
      <template #[`item.active`]="{ item }"
        ><v-row>
          <div class="d-flex align-center">
            <v-icon
              v-if="item.active"
              color="green"
              size="10px"
              class="mr-1"
              @click="showPopup(item)"
            >
              mdi-checkbox-blank-circle
            </v-icon>
            <v-icon
              v-else
              color="black"
              size="10px"
              class="mr-1"
              @click="showPopup(item)"
            >
              mdi-checkbox-blank-circle
            </v-icon>
            <span
              v-if="item.active"
              class="black--text"
              @click="showPopup(item)"
            >
              Paid
            </span>
            <span v-else class="black--text" @click="showPopup(item)">
              Unpaid
            </span>
          </div>
          <!-- <v-btn small color="red" @click="payBill(item)">Pay Now</v-btn> -->
          &nbsp;&nbsp;
          <template v-if="!item.active">
            <v-btn
              small
              style="font-size: 8px; padding: 4px 8px"
              color="orange"
              @click="payBill(item)"
              >Pay Now</v-btn
            >
          </template>
          <!-- <v-btn icon @click="generatePDF(item)">
            <v-icon>mdi-download</v-icon>
          </v-btn> -->
        </v-row>
      </template>
    </DataTableSSR>
    <!-- <v-dialog v-model="dialogVisible"> </v-dialog> -->

    <v-dialog v-model="dialogVisible">
      <v-card style="width: 70%; margin: auto">
        <v-card-title>View Invoice </v-card-title>
        <v-card-text>
          <div v-if="selectedInvoice">
            <v-container>
              <v-row>
                <v-col cols="7"
                  ><p
                    style="
                      font-size: 20px;
                      font-family: Verdana, Geneva, Tahoma, sans-serif;
                    "
                  >
                    Invoice #{{ selectedInvoice.invoice_id }}
                    <span
                      v-if="selectedInvoice.active"
                      class="green--text"
                      style="
                        background-color: green;
                        text-emphasis-color: white;
                      "
                      >Paid</span
                    >
                    <span
                      v-else
                      class="white--text"
                      style="background-color: red"
                      >Unpaid</span
                    >
                  </p>

                  <p>
                    Pay To :<br />
                    <!-- {{ selectedInvoice.service_name }} -->
                    admin@meetingme.live
                  </p>
                  <p class="elementor-icon-box-description">
                    BD Office: Level#2, S#7 <br />
                    10, Hatkhola Road, Tikatuli <br />
                    Dhaka-1203 <br /><br />
                  </p>
                </v-col>

                <v-col cols="3">
                  <p>Invoice Date:</p>
                  <p>Due Date:</p>
                  <p>Invoice To :</p>
                  <p>{{ selectedInvoice.email }}</p>
                  <p>{{ selectedInvoice.address }}</p>
                </v-col>

                <v-col cols="2">
                  <p>{{ selectedInvoice.bill_generate_date }}</p>
                  <p>{{ selectedInvoice.bill_due_date }}</p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="7">
                  <h2>Invoice Items</h2>
                  <p></p>
                  <p>Description</p>
                  <p>{{ selectedInvoice.detail }}</p>
                </v-col>
                <v-col cols="3">
                  <p style="margin-top: 50px">Subtotal</p>
                  <p>Credit</p>
                </v-col>
                <v-col cols="2">
                  <p></p>

                  <h2></h2>
                  <p>Amount</p>
                  <p>
                    &#x9F3; &nbsp;{{ selectedInvoice.bill_amount }}&nbsp;BDT
                  </p>

                  <p>&#x9F3; &nbsp;0 &nbsp;BDT</p>
                </v-col>
              </v-row>
              <v-row style="background-color: #add8e6">
                <v-col cols="7"> </v-col>
                <v-col cols="3">
                  <p>Total</p>
                </v-col>
                <v-col cols="2">
                  <p>
                    &#x9F3; &nbsp;{{ selectedInvoice.bill_amount }}&nbsp;BDT
                  </p>
                </v-col>
              </v-row>
            </v-container>
          </div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn color="primary" @click="dialogVisible = false">Close</v-btn>
          <v-btn icon @click="generatePDF()">
            <v-icon>mdi-download</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import DataTableSSR from "../../../components/global/DataTableSSR.vue";
import html2pdf from "html2pdf.js";

export default {
  components: { DataTableSSR },
  data: () => ({
    headers: [
      {
        text: "Invoice",
        value: "invoice_id",
        hover: "yellow",
      },
      {
        text: "Invoice Date",
        value: "bill_generate_date",
      },
      {
        text: "Due Date",
        value: "bill_due_date",
      },
      {
        text: "Total",
        value: "bill_amount",
      },
      {
        text: "Status",
        value: "active",
      },
    ],
    dialogVisible: false,
    selectedInvoice: null,
    heading: "Invoice",
  }),
  computed: {
    total_amount() {
      if (!this.item) {
        return 0;
      }
      return this.item.reduce(
        (total, fee) => total + Number(fee.bill_amount),
        0
      );
    },

    vat() {
      return this.total_amount * 0.05;
    },
    total_payable() {
      return this.total_amount + this.vat;
    },
  },
  methods: {
    showPopup(item) {
      this.selectedInvoice = item;
      this.dialogVisible = true;
    },
    payBill(item) {
      this.selectedInvoice = item;
      const selected_fees_ids = this.selectedInvoice.bill_amount;
      const id = this.selectedInvoice.invoice_id;

      const data = {
        bill_id: id,
        payment_amount: selected_fees_ids,
      };
      this.$api
        .post("/bill_pay/pay/", data)
        .then((res) => {
          const popup = window.open(
            res.data.payment_url,
            "payment",
            "width=600,height=700,top=1200,left=650, toolbar=no, menubar=no,scrollbars=no,resizable=no,location=no,"
          );
          const interval = setInterval(() => {
            if (popup.closed) {
              clearInterval(interval);
              this.getPayableFees();
              this.$store.dispatch("sms_payment");
              this.selected_fees = [];
            }
          }, 1000);
        })
        .catch((err) => {
          console.log(err.response.data);
        });
    },

    generatePDF() {
      const element = document.querySelector(".v-dialog");
      const options = {
        margin: 0,
        filename: "invoice.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 6 },
        jsPDF: {
          unit: "mm",
          format: "a4",
          orientation: "landscape",
        },
      };
      html2pdf().set(options).from(element).save();
    },
  },
};
</script>

<style></style>
